var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "유소견자 목록",
                tableId: "table",
                columns: _vm.grid.columns,
                gridHeightAuto: "",
                data: _vm.grid.data,
                columnSetting: false,
                checkClickFlag: false,
                selection: "multiple",
                rowKey: "heaCheckupResultOpinionId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: { label: "유소견자 등록", icon: "add" },
                        on: { btnClicked: _vm.suspectPop },
                      }),
                      _c("c-btn", {
                        attrs: { label: "유소견자 해제", icon: "add" },
                        on: { btnClicked: _vm.releasePop },
                      }),
                      _vm.grid.data.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              label: "유소견자에서 삭제",
                              icon: "delete",
                            },
                            on: { btnClicked: _vm.removeSuspect },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }