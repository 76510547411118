<template>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="유소견자 목록"
          tableId="table"
          :columns="grid.columns"
          gridHeightAuto
          :data="grid.data"
          :columnSetting="false"
          :checkClickFlag="false"
          selection="multiple"
          rowKey="heaCheckupResultOpinionId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="유소견자 등록" icon="add" @btnClicked="suspectPop" />
              <c-btn label="유소견자 해제" icon="add" @btnClicked="releasePop" />
              <c-btn v-if="grid.data.length>0" label="유소견자에서 삭제" icon="delete" 
                @btnClicked="removeSuspect" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </template>
  
  <script>
  import selectConfig from '@/js/selectConfig';
  import transactionConfig from '@/js/transactionConfig';
  export default {
    name: 'suspect-user',
    props: {
      popupParam: {
        type: Object,
        default: () => ({
          heaCheckupPlanId: '',
          plantCd: '',
          hospitalId: null,
          hospitalName: '',
        }),
      },
      temp: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        grid: {
          merge: [
            { index: 0, colName: 'userId', },
          ],
          columns: [
            {
              name: 'plantName',
              field: 'plantName',
              label: '사업장',
              align: 'center',
              style: 'width:80px',
              sortable: true,
            },
            {
              name: 'deptName',
              field: 'deptName',
              label: '부서',
              align: 'center',
              style: 'width:120px',
              sortable: false,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '대상자',
              align: 'center',
              style: 'width:80px',
              sortable: false,
            },
            {
              name: 'healthType',
              field: 'healthType',
              label: '건강구분',
              style: 'width:70px',
              align: 'center',
              sortable: false,
            },
            {
              name: 'appointDate',
              field: 'appointDate',
              label: '지정일',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'releaseDate',
              field: 'releaseDate',
              label: '해제일',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'releaseRemark',
              field: 'releaseRemark',
              label: '해제비고',
              align: 'left',
              style: 'width:300px',
              sortable: false,
            },
          ],
          data: [],
        },
        editable: true,
        listUrl: '',
        deleteUrl: '',
        popupOptions: {
          target: null,
          title: '',
          visible: false,
          top: '',
          param: {},
          closeCallback: null,
        },
        searchParam: {
          heaCheckupPlanId: '',
          healthTypes: ['C1', 'C2', 'CN', 'D1', 'D2', 'DN', 'R'],
          suitableFlags: [],
        },
        selectData: [],
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
      Object.assign(this.$data, this.$options.data());
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    watch: {
      temp() {
        this.getList();
      }
    },
    methods: {
      init() {
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.hea.checkup.suspect.user.history.url;
        this.deleteUrl = transactionConfig.hea.checkup.suspect.user.delete.url;
        this.getList();
      },
      getList() {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.popupParam;
        this.$http.request(_result => {
          console.log(_result.data)
          this.grid.data = _result.data
        },);
      },
      suspectPop() {
        this.searchParam.heaCheckupPlanId = this.popupParam.heaCheckupPlanId
        this.popupOptions.title = '유소견자 등록';
        this.popupOptions.param = this.searchParam
        this.popupOptions.target = () => import(`${'./suspectUserRegisterPop.vue'}`);
        // this.popupOptions.isFull = true;
        this.popupOptions.width = '85%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeSuspectPop;
      },
      closeSuspectPop(result) {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        console.log(result)
        this.getList();
      },
      releasePop() {
        this.searchParam.heaCheckupPlanId = this.popupParam.heaCheckupPlanId
        this.popupOptions.title = '유소견자 해제';
        this.popupOptions.param = this.searchParam
        this.popupOptions.target = () => import(`${'./suspectUserReleasePop.vue'}`);
        // this.popupOptions.isFull = true;
        this.popupOptions.width = '85%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeRealsePop;
      },
      closeRealsePop() {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        this.getList();
      },
      removeSuspect() {
        let selectData = this.$refs['table'].selected;
        if (!selectData || selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '선택된 항목이 없습니다.',
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '삭제하시겠습니까? '+' ['+selectData.length+' 건]',
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.deleteUrl
              this.$http.type = 'DELETE';
              this.$http.param = {
                data: Object.values(selectData)
              }
              this.$http.request(() => {
                this.$_.forEach(selectData, item => {
                  this.grid.data = this.$_.reject(this.grid.data, { suspectId: item.suspectId })
                })
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                // this.getDetail();
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      },
    }
  };
  </script>
  